body {
  /* Variables */
  --color-primary: #554E95;
  --color-secondary: #d9d9d9;
  --color-primary-dark: #332c73;
  --text-default: #373d3f;

  /* Global styles */
  margin: 0;
  padding: 0;
  background: var(--color-secondary);
}